//todo: make read more button work.

document.addEventListener("DOMContentLoaded", function() {
    let button_wrapper = document.getElementById('button-wrapper');
    let video_text = document.getElementById('video-text');
    let original_video_text = video_text.innerText;
    let truncated_text = original_video_text.substring(0, 600) + '...';

    // video_text.style.transition = "all 0.5s ease";

    if (original_video_text.length > 600) {
        readLess();
    }



    function readMore() {
        let read_less_icon = document.createElement('i')
        read_less_icon.setAttribute('class', 'fas fa-angle-up ms-3');
        read_less_icon.setAttribute('id', 'read-less-button');

        let read_less_button = document.createElement("button");
        read_less_button.setAttribute('id', 'read-less');
        read_less_button.setAttribute('class', 'btn btn-lg btn-orange');
        read_less_button.innerHTML = 'Read Less ';
        read_less_button.appendChild(read_less_icon);

        document.getElementById('video-text').innerText = original_video_text;

        let read_more = document.getElementById('read-more');
        if (read_more != null) {
            read_more.outerHTML = "";
        }
        button_wrapper.prepend(read_less_button);
        document.getElementById('read-less').onclick = function () {
            readLess();

            let video_wrapper = document.getElementById('video-wrapper');
            video_wrapper.scrollIntoView(true);
        }
    }

    function readLess() {
        let read_more_icon = document.createElement('i');
        read_more_icon.setAttribute('class', 'fas fa-angle-down ms-3');
        read_more_icon.setAttribute('id', 'read-more-icon');

        let read_more_button = document.createElement("button");
        read_more_button.setAttribute('id', 'read-more');
        read_more_button.setAttribute('class', 'btn btn-lg btn-orange');
        read_more_button.innerHTML = 'Read More ';
        read_more_button.appendChild(read_more_icon);

        document.getElementById('video-text').innerText = truncated_text;

        let read_less = document.getElementById('read-less');

        if (read_less != null) {
            read_less.outerHTML = "";
        }
        button_wrapper.prepend(read_more_button);
        document.getElementById('read-more').onclick = function () {
            readMore();
        }
    }

});
